<template>
  <div class="coinComponents">
    <b-container fluid class="fgCoin">
      <div class="card">
        <b-card-header>
          <h4 class="cardHeaderTitle">
            {{ $t("m.coinTitle") }}
          </h4>
        </b-card-header>
        <b-card-body>
          <a-table
            class="fgCoinTable"
            :columns="columns"
            :pagination="false"
            :data-source="dataSource"
            :loading="loading"
            rowKey="id"
            :scroll="{ x: 'calc(500px + 20%)' }"
          >
            <!-- title 国际化 begin-->
            <template slot="nameT">{{ $t("m.plat") }}</template>
            <template slot="priceT">{{ $t("m.price") }}</template>
            <template slot="volume24hT">{{ $t("m.volume24h") }}</template>
            <template slot="liquidityT">{{ $t("m.liquidity") }}</template>
            <!-- title 国际化 end-->
            <template slot="name" slot-scope="text, record">
              <img class="coinIcon" :src="record.sourceImg" alt="..." />
              {{ text }}
            </template>
            <template slot="price" slot-scope="text">
              <span class="priceText">${{ text }}</span>
            </template>
          </a-table>
        </b-card-body>
      </div>
    </b-container>
  </div>
</template>

<script>
import Vue from "vue";
import { Table } from "ant-design-vue";
Vue.use(Table);

export default {
  name: "coinComponents",
  asyncData: function ({ store }) {
    return store.dispatch("GET_COIN"); // 返回promise
  },
  computed: {
    coin: {
      get() {
        return this.$store.state.coin;
      },
      set(val) {
        this.$store.state.coin = val;
      },
    },
  },
  props: ["time", "countdown"],
  components: {},
  data() {
    return {
      columns: [
        {
          dataIndex: "name",
          key: "name",
          scopedSlots: { customRender: "name", title: "nameT" },
        },
        {
          dataIndex: "price",
          key: "price",
          scopedSlots: { customRender: "price", title: "priceT" },
        },
        {
          dataIndex: "volume24h",
          key: "volume24h",
          scopedSlots: { title: "volume24hT" },
        },
        {
          dataIndex: "liquidity",
          key: "liquidity",
          scopedSlots: { title: "liquidityT" },
        },
      ],
      dataSource: [],
      loading: true,
      coinTimer: "",
    };
  },
  created() {
    // this.$store.dispatch("GET_COIN");
  },
  watch: {
    // 监听store数据，有则渲染
    "$store.state.coin"(newval, olval) {
      var _this = this;
      if (newval) {
        _this.coinTableData();
      }
    },
    countdown: {
      handler(newVal, oldVal) {
        if (newVal === 0) {
          this.$store.dispatch("GET_COIN");
          // this.coinTableData();
        }
      },
      deep: true,
    },
  },
  mounted() {
    // var _this = this;
    // _this.coinTimer = setInterval(() => {
    //   _this.$store.dispatch("GET_COIN");
    //   _this.coinTableData();
    // }, _this.time);
  },
  methods: {
    coinTableData() {
      this.loading = true;
      this.coin = this.$store.state.coin;
      this.dataSource = [];
      this.coin.forEach((item, index) => {
        let imgName = "";
        if (item.exchange.name === "Huobi Global") {
          imgName = "./img/huobi.png";
        } else if (item.exchange.name === "Binance") {
          imgName = "./img/binance.png";
        } else if (item.exchange.name === "OKEx") {
          imgName = "./img/okex.png";
        }
        let mixData = {
          id: item.exchange.id,
          sourceImg: imgName,
          name: item.exchange.name,
          price: item.quote.USD.price.toFixed(2),
          liquidity: parseInt(item.quote.USD.effective_liquidity),
          volume24h: parseInt(item.quote.USD.volume_24h),
        };
        this.dataSource.push(mixData);
      });
      if (this.$store.state.coin) {
        this.loading = false;
      }
      // this.$forceUpdate();
    },
  },
  beforeDestroy() {
    this.coinTimer = null;
    clearInterval(this.coinTimer);
  },
};
</script>
<style lang="less" scoped>
.coinComponents {
  /deep/.fgCoin {
    margin: 0 11.5555rem;
    position: relative;
    width: auto;
    margin-top: 1.5rem;
    .card {
      margin-bottom: 1.5rem;
      border-color: #edf2f9;
      border-radius: 10px;
      box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
      .card-header {
        padding: 1rem 1.5rem;
        margin-bottom: 0;
        background-color: transparent;
        border-bottom: 1px solid #edf2f9;
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 60px;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        h4 {
          flex: 1;
          text-align: left;
          font-size: 1.3rem;
          color: #12263f;
          margin-bottom: 0;
        }
      }
      .card-body {
        padding: 0 !important;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .coinComponents {
    /deep/.fgCoin {
      margin: 0 11.5555rem;
    }
  }
}
@media only screen and (max-width: 768px) {
  .coinComponents {
    /deep/.fgCoin {
      margin: 0;
    }
  }
}
</style>
<style>
/* antd table 个别样式不能加scope， 否则样式失效 */
/* 此处是渲染table col bg hover */
.fgCoinTable .ant-table-thead tr th {
  color: #7ABBC3;
  font-size: 1.05rem;
  background-color: #F1FEFF;
  /* border-bottom: 1px solid #444 !important; */
}
.fgCoinTable .ant-table-tbody tr:hover td {
  background-color: #f9fbfd !important;
}
.fgCoinTable .ant-table-tbody tr td {
  color: #777;
  font-size: 1.05rem;
}
.coinIcon {
  width: 20px;
  height: 20px;
  vertical-align: top;
  margin-right: 5px;
}
.priceText {
  color: #666 !important;
}
.ant-table td {
  white-space: nowrap;
}
.ant-table-body {
  overflow-x: auto !important;
}
</style>
