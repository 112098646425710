<template>
  <div class="baselineChart">
    <b-container fluid class="fgGas">
      <b-row>
        <b-col>
          <div id="main" style="width: 99%; height: 350px"></div>
        </b-col>
        <b-col>
          <div id="gasChart" style="width: 99%; height: 350px"></div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "baselineChart",

  watch: {
    // 监听store数据，有则渲染
    "$store.state.article"(newval, olval) {
      var _this = this;
      // if (newval) {
      //   let updateData = JSON.parse(JSON.stringify(this.activeGasChart));
      //   if (
      //     updateData.length && this.timestampToTime(newval.timestamp) !== updateData[0].name
      //   ) {
      //     let watchObj = {};
      //     watchObj.name = this.timestampToTime(newval.timestamp);
      //     watchObj.value = [
      //       this.timestampToTime(newval.timestamp),
      //       newval.preGas_32.toFixed(3),
      //     ];
      //     updateData.shift();
      //     updateData.push(watchObj);

      //     _this.gasEcharts.setOption({
      //       series: [
      //         {
      //           data: updateData,
      //         },
      //       ],
      //     });
      //   }
      // }
    },
  },

  props: ["article"],
  data() {
    return {
      initBaseline: 2.5,
      passedBaselineData: [],
      passedPowerData: [],
      activeGasChart: [],
      activeGasChart64: [],
      beforeHandle32: [],
      beforeHandle64: [],
      timer: "",
    };
  },
  created() {},
  mounted() {
    axios.post(`${this.$originHost}/api/v1/getFilListDay30`).then((res) => {
      if (res.data.length) {
        res.data.forEach((item, index) => {
          let baselineObj = {},
            powerObj = {};
          baselineObj.name = this.timestampToTime(item.timestamp);
          baselineObj.value = [
            this.timestampToTime(item.timestamp),
            this.countBaseline(item.latest_height),
          ];
          powerObj.name = this.timestampToTime(item.timestamp);
          powerObj.value = [
            this.timestampToTime(item.timestamp),
            this.countQualityAdjPower(item.total_quality_power),
          ];
          // obj.baseline = this.countBaseline(item.latest_height);
          // obj.power = this.countQualityAdjPower(item.total_quality_power);
          this.passedBaselineData.push(baselineObj);
          this.passedPowerData.push(powerObj);
        });
        this.$nextTick(() => {
          this.myEcharts();
        });
      }
    });
    axios.post(`${this.$originHost}/api/v1/getFilList`).then((res) => {
      if (res.data.length) {
        this.beforeHandle32 = [];
        res.data.forEach((item, index) => {
          let activeObj = {};
          activeObj.name = this.timestampToTime(item.timestamp);
          if (item.preGas_1T !== null) {
            let countGas = item.preGas_1T;
            activeObj.value = [
              this.timestampToTime(item.timestamp),
              countGas.toFixed(3),
            ];
          }
          this.beforeHandle32.push(activeObj);
        });
        this.activeGasChart = this.beforeHandle32.filter(this.handleArr);
        console.log(this.activeGasChart);
        this.$nextTick(() => {
          this.gasEcharts();
        });
      }
    });
    axios.post(`${this.$originHost}/api/v1/get64GFilList`).then((res) => {
      if (res.data.length) {
        this.beforeHandle64 = [];
        res.data.forEach((item, index) => {
          let activeObj = {};
          activeObj.name = this.timestampToTime(item.timestamp);
          if (item.preGas_1T !== null) {
            let countGas = item.preGas_1T;
            activeObj.value = [
              this.timestampToTime(item.timestamp),
              countGas.toFixed(3),
            ];
          }
          this.beforeHandle64.push(activeObj);
        });
        this.activeGasChart64 = this.beforeHandle64.filter(this.handleArr);
        console.log(this.activeGasChart64);
        this.$nextTick(() => {
          this.gasEcharts();
        });
      }
    });
    // setTimeout(() => {

    // }, 1500);
  },
  methods: {
    handleArr(val, index) {
      return true;
    },
    myEcharts() {
      let chartDom = document.getElementById("main");
      let myChart = this.$echarts.init(chartDom);
      let option;

      function randomData() {
        now = new Date(+now + oneDay);
        value = value + Math.random() * 21 - 10;
        return {
          name: now.toString(),
          value: [
            [now.getFullYear(), now.getMonth() + 1, now.getDate()].join("/"),
            Math.round(value),
          ],
        };
      }

      let dataBaseline = JSON.parse(JSON.stringify(this.passedBaselineData));
      let dataPower = JSON.parse(JSON.stringify(this.passedPowerData));
      // var data = []
      // var now = +new Date(1997, 9, 3);
      // var oneDay = 24 * 3600 * 1000;
      // var value = Math.random() * 1000;
      // for (var i = 0; i < 1000; i++) {
      //   data.push(randomData());
      // }

      option = {
        title: {
          text: "近30天基线/全网有效算力走势",
          textStyle: {
            color: "#12263f",
            fontSize: 16,
            fontWeight: "normal",
          },
        },
        grid: {
          left: "3%",
          right: "3%",
          bottom: "3%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          formatter: function(datas) {
            var res = datas[0].name + "<br/>";
            for (var i = 0, length = datas.length; i < length; i++) {
              res +=
                "<br/>" +
                datas[i].marker +
                datas[i].seriesName +
                "：" +
                '<span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900">' +
                datas[i].data.value[1] +
                " EiB" +
                "</span>" +
                "<br/>";
            }
            return res;
          },
          axisPointer: {
            animation: true,
          },
        },
        legend: {
          data: ["基线", "全网有效算力"],
          y: "10%",
        },
        xAxis: {
          type: "time",
          splitLine: {
            show: false,
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            formatter: "{value} EiB",
          },
          max: function(obj) {
            //解决因数据值范围相差太大或是太小曲线显示不美观问题
            var ma = obj.max;
            var mi = obj.min;
            var val = Math.ceil(ma + (ma - mi) / 2);
            return val;
          },
          min: 2,
          // min: function (obj) {
          //   var ma = obj.max;
          //   var mi = obj.min;
          //   var val = Math.ceil(mi - (ma - mi) / 2);
          //   return mi == 0 ? 0 : val;
          // },
          // boundaryGap: [0, "100%"],
          splitLine: {
            show: true,
          },
        },
        series: [
          {
            name: "基线",
            type: "line",
            showSymbol: true,
            hoverAnimation: false,
            data: dataBaseline,
          },
          {
            name: "全网有效算力",
            type: "line",
            showSymbol: true,
            hoverAnimation: false,
            data: dataPower,
          },
        ],
      };

      // setInterval(function () {
      //   for (var i = 0; i < 5; i++) {
      //     data.shift();
      //     data.push(randomData());
      //   }

      //   myChart.setOption({
      //     series: [
      //       {
      //         data: data,
      //       },
      //     ],
      //   });
      // }, 1000);

      option && myChart.setOption(option);
    },
    gasEcharts() {
      let chartDom = document.getElementById("gasChart");
      let myChart = this.$echarts.init(chartDom);
      let option;

      function randomData() {
        now = new Date(+now + oneDay);
        value = value + Math.random() * 21 - 10;
        return {
          name: now.toString(),
          value: [
            [now.getFullYear(), now.getMonth() + 1, now.getDate()].join("/"),
            Math.round(value),
          ],
        };
      }

      let activeGasChartData = JSON.parse(JSON.stringify(this.activeGasChart)),
        activeGasChart64Data = JSON.parse(
          JSON.stringify(this.activeGasChart64)
        );
      // var data = []
      // var now = +new Date(1997, 9, 3);
      // var oneDay = 24 * 3600 * 1000;
      // var value = Math.random() * 1000;
      // for (var i = 0; i < 1000; i++) {
      //   data.push(randomData());
      // }

      option = {
        title: {
          text: "近3H 封装Gas费用(FIL/TiB)",
          textStyle: {
            color: "#12263f",
            fontSize: 16,
            fontWeight: "normal",
          },
        },
        grid: {
          left: "3%",
          right: "3%",
          bottom: "3%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          // formatter: function (datas) {
          //   var res = datas[0].name + "<br/>";
          //   for (var i = 0, length = datas.length; i < length; i++) {
          //     res +=
          //       "<br/>" +
          //       datas[i].marker +
          //       datas[i].seriesName +
          //       "：" +
          //       '<span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900">' +
          //       datas[i].data.value[1] +
          //       " Fil" +
          //       "</span>" +
          //       "<br/>";
          //   }
          //   return res;
          // },
          // axisPointer: {
          //   animation: true,
          // },
        },
        legend: {
          data: ["32G矿工", "64G矿工"],
          y: "10%",
        },
        xAxis: {
          type: "time",
          splitLine: {
            show: false,
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            formatter: "{value} Fil",
          },
          max: function(obj) {
            //解决因数据值范围相差太大或是太小曲线显示不美观问题
            let ma = obj.max;
            let mi = obj.min;
            let val = ma + (ma - mi) / 2;
            val = Math.ceil(val * 10) / 10;
            return val;
          },
          min: function(obj) {
            let ma = obj.max;
            let mi = obj.min;
            let val = mi - (ma - mi) / 2;
            val = Math.floor(val * 10) / 10;
            if (val <= 0) {
              val = 0;
            }
            return mi == 0 ? 0 : val;
          },
          // boundaryGap: [0, "100%"],
          splitLine: {
            show: true,
          },
        },
        series: [
          {
            name: "32G矿工",
            type: "line",
            showSymbol: false,
            smooth: true,
            hoverAnimation: false,
            data: activeGasChartData,
          },
          {
            name: "64G矿工",
            type: "line",
            showSymbol: false,
            smooth: true,
            hoverAnimation: false,
            data: activeGasChart64Data,
          },
        ],
      };

      let updateData = JSON.parse(JSON.stringify(this.activeGasChart));
      let updateData64 = JSON.parse(JSON.stringify(this.activeGasChart64));
      this.timer = setInterval(() => {
        if (this.$store.state.article) {
          if (
            updateData.length &&
            this.timestampToTime(this.$store.state.article.timestamp) !==
              updateData[0].name
          ) {
            let watchObj = {};
            watchObj.name = this.timestampToTime(
              this.$store.state.article.timestamp
            );
            let count32 = this.$store.state.article.preGas_1T;
            watchObj.value = [
              this.timestampToTime(this.$store.state.article.timestamp),
              count32.toFixed(3),
            ];
            updateData.pop();
            updateData.unshift(watchObj); //更新数据
          }
        }
        if (this.$store.state.article64) {
          if (
            updateData64.length &&
            this.timestampToTime(this.$store.state.article64.timestamp) !==
              updateData64[0].name
          ) {
            let watchObj64 = {};
            watchObj64.name = this.timestampToTime(
              this.$store.state.article64.timestamp
            );
            let count64 = this.$store.state.article64.preGas_1T;
            watchObj64.value = [
              this.timestampToTime(this.$store.state.article64.timestamp),
              count64.toFixed(3),
            ];
            updateData64.pop();
            updateData64.unshift(watchObj64); //更新数据
          }
        }
        myChart.setOption({
          series: [
            {
              data: updateData,
            },
            {
              data: updateData64,
            },
          ],
        });
      }, 30000);


      option && myChart.setOption(option);
    },
    add0(m) {
      return m < 10 ? "0" + m : m;
    },
    timestampToTime(timestamp) {
      //时间戳转换
      let time = "";
      if (timestamp.toString().length < 13) {
        time = new Date(timestamp * 1000);
      } else {
        time = new Date(timestamp);
      }
      var date = new Date(time); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D = this.add0(date.getDate()) + " ";
      var h = this.add0(date.getHours()) + ":";
      var m = this.add0(date.getMinutes()) + ":";
      var s = this.add0(date.getSeconds());
      return Y + M + D + h + m + s;
    },
    countBaseline(height) {
      //Math.LIN(1+1)指的是(1+100%)
      let baseline =
        this.initBaseline * Math.exp((Math.LN2 / 31536000) * height * 30);
      return baseline;
    },
    countQualityAdjPower(power) {
      let totalPower = power;
      totalPower = parseFloat(
        power / (1024 * 1024 * 1024 * 1024 * 1024 * 1024)
      ).toFixed(3);
      return totalPower;
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="less" scoped>
.baselineChart {
  .fgGas {
    padding: 20px;
    box-sizing: content-box;
  }
  /deep/.row {
    margin-left: 0;
    margin-right: 0;
    .col {
      padding: 0;
    }
  }
}
#main,
#gasChart {
  text-align: left;
}
@media only screen and (max-width: 768px) {
  .baselineChart {
    .col {
      flex-basis: auto;
      #gasChart {
        margin-top: 20px;
      }
    }
  }
}
</style>
