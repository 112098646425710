<template>
  <div class="shareImg" v-if="popShow">
    <div class="shareImgBox">
      <div class="imgBox"><img :src="src" alt="" /></div>
      <p>{{ $t("m.longPress") }}<i class="iconfont iconchangan"></i></p>
      <i class="iconfont iconguanbi" @click="closeMe()"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: "shareImg",

  data() {
    return {};
  },
  props: ["src", "popShow"],
  created() {},
  methods: {
    closeMe() {
      this.$emit("update:popShow", "");
    },
  },
};
</script>

<style lang="less" scoped>
.shareImg {
  z-index:11;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  .shareImgBox {
    max-width: 90%;
    height: 88%;
    margin: 5% auto 0;
    text-align: center;
    .imgBox {
      width: 100%;
      img {
        width: 100%;
      }
    }
    p {
      color: #fff;
      font-size: 1.2rem;
      display: inline-flex;
      align-items: center;
      i {
        display: inline;
        &:before {
          font-size: 2.2rem;
        }
      }
    }
    i {
      display: block;
      margin: 0 auto;
      &:before {
        color: #fff;
        font-size: 3rem;
      }
    }
  }
}
@media only screen and (max-width: 480px) {
  .shareImg {
    .shareImgBox {
      .imgBox {
        height: 90%;
        img {
          width: auto;
          height: 100%;
        }
      }
    }
  }
}
</style>