<template>
  <div class="sectorComponents">
    <b-container fluid class="fgSector">
      <a-row :gutter="12">
        <a-col :sm="12" :md="12">
          <div class="card">
            <b-card-header>
              <h4 class="cardHeaderTitle">
                {{ $t("m.diff32Title") }}
              </h4>
            </b-card-header>
            <b-card-body>
              <a-table
                :columns="columns"
                :pagination="true"
                :data-source="dataSource32"
                :loading="loading"
                rowKey="id"
                :scroll="{ x: 'calc(400px + 20%)' }"
              >
                <!-- title 国际化 begin-->
                <template slot="idT">{{ $t("m.minerId") }}</template>
                <template slot="nameT">{{ $t("m.minerName") }}</template>
                <template slot="qualityAdjPowerT">{{
                  $t("m.minerQualityAdjPowerProportion")
                }}</template>
                <template slot="totalRewardsT"
                  >{{ $t("m.blockRewards") }}
                </template>
                <template slot="efficiencyT">{{ $t("m.efficiency") }}</template>
                <template slot="CalculateForceGrowthT">{{
                  $t("m.powerGrowth")
                }}</template>
                <!-- <template slot="priceT">{{ $t("m.price") }}</template> -->
                <!-- title 国际化 end-->
                <template slot="qualityAdjPower" slot-scope="text, record">
                  <span>{{ text }} / {{ record.proportion }}%</span>
                </template>
                <template slot="totalRewards" slot-scope="text">
                  <span>{{ text }} FIL</span>
                </template>
                <template slot="efficiency" slot-scope="text">
                  <span>{{ text }} FTL/TiB</span>
                </template>
              </a-table>
            </b-card-body>
          </div>
        </a-col>
        <a-col :sm="12" :md="12">
          <div class="card">
            <b-card-header>
              <h4 class="cardHeaderTitle">
                {{ $t("m.diff64Title") }}
              </h4>
            </b-card-header>
            <b-card-body>
              <a-table
                :columns="columns"
                :pagination="true"
                :data-source="dataSource64"
                :loading="loading"
                rowKey="id"
                :scroll="{ x: 'calc(400px + 20%)' }"
              >
                <!-- title 国际化 begin-->
                <template slot="idT">{{ $t("m.minerId") }}</template>
                <template slot="nameT">{{ $t("m.minerName") }}</template>
                <template slot="qualityAdjPowerT">{{
                  $t("m.minerQualityAdjPowerProportion")
                }}</template>
                <template slot="totalRewardsT"
                  >{{ $t("m.blockRewards") }}
                </template>
                <template slot="efficiencyT">{{ $t("m.efficiency") }}</template>
                <template slot="CalculateForceGrowthT">{{
                  $t("m.powerGrowth")
                }}</template>
                <!-- <template slot="priceT">{{ $t("m.price") }}</template> -->
                <!-- title 国际化 end-->
                <template slot="name" slot-scope="text">
                  <span>{{ text || "----" }}</span>
                </template>
                <template slot="qualityAdjPower" slot-scope="text, record">
                  <span>{{ text }} / {{ record.proportion }}%</span>
                </template>
                <template slot="totalRewards" slot-scope="text">
                  <span>{{ text }} FIL</span>
                </template>
                <template slot="efficiency" slot-scope="text">
                  <span>{{ text }} FTL/TiB</span>
                </template>
              </a-table>
            </b-card-body>
          </div>
        </a-col>
      </a-row>
    </b-container>
    <!-- <sectorPieChart
      v-if="this.dataSource32.length > 0"
      :dataSource.sync="miners.dataSource"
    ></sectorPieChart> -->
  </div>
</template>

<script>
import Vue from "vue";
import { Table, Row, Col } from "ant-design-vue";
// import sectorPieChart from "../../../components/sectorPieChart";
Vue.use(Table);
Vue.use(Row);
Vue.use(Col);

export default {
  computed: {
    miners: {
      get() {
        return this.$store.state.miners;
      },
      set(val) {
        this.$store.state.miners = val;
      },
    },
  },
  props: ["time", "countdown"],
  components: {
    // sectorPieChart,
  },
  data() {
    return {
      columns: [
        {
          dataIndex: "id",
          key: "id",
          width: 80,
          // fixed: "left",
          scopedSlots: { title: "idT" },
        },
        {
          dataIndex: "name",
          key: "name",
          width: 140,
          scopedSlots: { customRender: "name", title: "nameT" },
        },
        {
          dataIndex: "qualityAdjPower",
          key: "qualityAdjPower",
          width: 120,
          scopedSlots: {
            customRender: "qualityAdjPower",
            title: "qualityAdjPowerT",
          },
        },
        // {
        //   dataIndex: "totalRewards",
        //   key: "totalRewards",
        //   width: 100,
        //   scopedSlots: { customRender: "totalRewards", title: "totalRewardsT" },
        // },
        {
          dataIndex: "efficiency",
          key: "efficiency",
          width: 100,
          scopedSlots: { customRender: "efficiency", title: "efficiencyT" },
        },
        {
          dataIndex: "CalculateForceGrowth",
          key: "CalculateForceGrowth",
          width: 100,
          scopedSlots: { title: "CalculateForceGrowthT" },
        },
      ],
      dataSource32: [],
      dataSource64: [],
      loading: true,
      minersTimer: "",
    };
  },
  created() {
    // this.$store.dispatch("GET_MINERS");
  },
  watch: {
    // 监听store数据，有则渲染
    "$store.state.miners"(newval, olval) {
      var _this = this;
      if (newval) {
        _this.tableData();
      }
    },
  },
  mounted() {},
  methods: {
    tableData() {
      this.loading = true;
      this.miners = this.$store.state.miners;
      this.miners.dataSource = this.miners.dataSource.filter(
        (item) => item.id !== null
      );
      this.dataSource32 = this.miners.dataSource.filter(
        (item) => item.sectorSize == "32.00 GiB"
      );
      this.dataSource64 = this.miners.dataSource.filter(
        (item) => item.sectorSize == "64.00 GiB"
      );
      if (this.$store.state.miners) {
        this.loading = false;
      }
      // this.$forceUpdate();
    },
  },
  beforeDestroy() {
    this.minersTimer = null;
    clearInterval(this.minersTimer);
  },
};
</script>
<style lang="less" scoped>
.sectorComponents {
  /deep/.fgSector {
    margin: 0 11.5555rem;
    position: relative;
    width: auto;
    margin-top: 1.5rem;
    .card {
      margin-bottom: 1.5rem;
      border-color: #edf2f9;
      box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
      .card-header {
        padding: 1rem 1.5rem;
        margin-bottom: 0;
        background-color: transparent;
        border-bottom: 0 solid #edf2f9;
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 60px;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        h4 {
          flex: 1;
          text-align: left;
          font-size: 1.3rem;
          color: #12263f;
          margin-bottom: 0;
        }
      }
      .card-body {
        padding: 0 !important;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .sectorComponents {
    /deep/.fgSector {
      margin: 0 11.5555rem;
    }
  }
}
@media only screen and (max-width: 768px) {
  .sectorComponents {
    /deep/.fgSector {
      margin: 0;
    }
  }
}
</style>
<style>
/* antd table 个别样式不能加scope， 否则样式失效 */
/* 此处是渲染table col bg hover */
.fgSector .ant-table-pagination.ant-pagination {
  float: none !important;
}
.fgSector .ant-table-thead tr th {
  color: #95aac9;
  font-size: 1.05rem;
  background-color: #f9fbfd;
  /* border-bottom: 1px solid #444 !important; */
}
.fgSector .ant-table-tbody tr:hover td {
  background-color: #f9fbfd !important;
}
.fgSector .ant-table-tbody tr td {
  color: #777;
  font-size: 1.05rem;
}
</style>
