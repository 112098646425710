<template>
  <div class="gasComponents">
    <b-container fluid class="fgGas">
      <div class="card">
        <b-card-header>
          <h4 class="cardHeaderTitle">
            {{ $t("m.indexTitle") }} &nbsp;
            <compute-number
              v-if="tipsetHeight"
              :heightNumber.sync="tipsetHeight"
              ifShotting.sync="true"
            ></compute-number>
          </h4>
          <b-nav class="fgGasNav">
            <b-nav-item
              @click="clickGasNav(0)"
              :class="{ active: clickNum === 0 }"
              >{{ $t("m.everyG") }}</b-nav-item
            >
            <b-nav-item
              @click="clickGasNav(1)"
              :class="{ active: clickNum === 1 }"
              >{{ $t("m.perT") }}</b-nav-item
            >
            <b-nav-item
              @click="clickGasNav(2)"
              :class="{ active: clickNum === 2 }"
            >
              {{ $t("m.perP") }}</b-nav-item
            >
          </b-nav>
        </b-card-header>
        <b-card-body>
          <b-row class="fgGasRow align-items-center">
            <b-col lg="4">
              <h5>{{ $t("m.totalCost") }}</h5>
              <span class="h1 numBox primary"
                >{{ total | numFilter }} <small>Fil</small></span
              >
            </b-col>
            <b-col lg="4">
              <h5>{{ $t("m.PledgeFee") }}</h5>
              <span class="h1 numBox success"
                >{{ payment | numFilter }} <small>Fil</small></span
              ></b-col
            >
            <b-col lg="4">
              <h5>{{ $t("m.gasFee") }}</h5>
              <span class="h1 numBox danger"
                >{{ preGas | numFilter }} <small>Fil</small></span
              ></b-col
            >
          </b-row>
        </b-card-body>
      </div>
    </b-container>
    <!-- <baseline :article.sync="$store.state.article"></baseline> -->
  </div>
</template>

<script>
import computeNumber from "../../../components/scrollNumber";
import baseline from "../../../components/baselineChart";

export default {
  name: "gasComponents",
  computed: {
    article: {
      get() {
        return this.$store.state.article;
      },
      set(val) {
        this.$store.state.article = val;
      },
    },
  },
  props: ["time", "countdown"],
  components: {
    computeNumber,
    baseline,
  },
  data() {
    return {
      clickNum: 1,
      // article: [],
      gasTimer: "",
      tipsetHeight: "",
      payment: "",
      preGas: "",
      total: "",
    };
  },
  filters: {
    numFilter(value) {
      let realVal = "";
      if (!isNaN(value) && value !== "") {
        // 截取当前数据到小数点后两位
        realVal = parseFloat(value).toFixed(5);
      } else {
        realVal = "--";
      }
      return realVal;
    },
  },
  created() {
    // 测试app api
    // this.$store.dispatch("GET_TOP20MINER");
    // this.$store.dispatch("GET_STATICINFO");
    // this.$store.dispatch("GET_DAYTRENDINFO");
    // this.$store.dispatch("GET_WEEKTRENDINFO");
  },
  watch: {
    // 监听store数据，有则渲染
    "$store.state.article"(newval, olval) {
      var _this = this;
      if (newval) {
        _this.article = _this.$store.state.article;
        _this.getRealMining(_this.clickNum);
      }
    },

    countdown: {
      handler(newVal, oldVal) {
        if (newVal === 0) {
          this.$store.dispatch("GET_ARTICLE", { type: "32G" });
          this.$store.dispatch("GET_ARTICLE", { type: "64G" });
        }
      },
      deep: true,
    },
  },
  mounted() {
    // var _this = this; //声明一个变量指向Vue实例this，保证作用域一致
    // _this.gasTimer = setInterval(() => {
    //   _this.$store.dispatch("GET_ARTICLE");
    //   _this.getRealMining(_this.clickNum);
    // }, _this.time);
    this.$nextTick(() => {
      this.getRealMining(this.clickNum);
    });
  },
  methods: {
    getRealMining(num) {
      let gasMap = {
        0: {
          total: "total_32",
          payment: "payment_32",
          preGas: "preGas_32",
        },
        1: {
          total: "total_1T",
          payment: "payment_1T",
          preGas: "preGas_1T",
        },
        2: {
          total: "total_1P",
          payment: "payment_1P",
          preGas: "preGas_1P",
        },
      };
      this.total = this.article[gasMap[num]["total"]];
      this.payment = this.article[gasMap[num]["payment"]];
      this.preGas = this.article[gasMap[num]["preGas"]];
      // console.log("this.preGatest: ", this.preGas);
      this.tipsetHeight = this.article.latest_height;
    },
    clickGasNav(num) {
      var _this = this; //声明一个变量指向Vue实例this，保证作用域一致
      _this.clickNum = num;
      _this.getRealMining(num);
    },
  },
  beforeDestroy() {
    this.gasTimer = null;
    clearInterval(this.gasTimer);
  },
};
</script>
<style lang="less" scoped>
.gasComponents {
  /deep/.fgGas {
    // margin: 0 11.5555rem;
    position: relative;
    width: auto;

    margin: 0;
    margin-top: -8rem;
    // margin-top: 1.5rem;
    .card {
      margin-bottom: 1.5rem;
      border-color: #edf2f9;
      box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
      border-radius: 10px;
      .card-header {
        padding: 1rem 1.5rem;
        margin-bottom: 0;
        background-color: transparent;
        border-bottom: 1px solid #edf2f9;
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 60px;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        h4 {
          flex: 1;
          text-align: left;
          font-size: 1.3rem;
          color: #12263f;
          margin-bottom: 0;
        }
        .fgGasNav {
          .nav-item {
            .nav-link {
              color: #6e84a3;
              font-size: 1.1rem;
              padding-top: calc((60px - 1em * 1.5) / 2) !important;
              padding-bottom: calc((60px - 1em * 1.5) / 2) !important;
              &:hover {
                color: #0cc2d3;
                transition: color 0.15s ease-in-out,
                  background-color 0.15s ease-in-out,
                  border-color 0.15s ease-in-out;
              }
            }
            &.active {
              .nav-link {
                color: #0cc2d3;
                background-color: transparent;
                border-bottom: 1px solid #0cc2d3;
                transition: border-color 0.15s ease-in-out;
              }
            }
          }
        }
      }

      .card-body {
        flex: 1 1 auto;
        padding: 1.7rem 1.7rem;
        .fgGasRow {
          h5 {
            color: #87969a !important;
            text-align: left;
            font-size: 1.15rem;
            margin-bottom: 0.75rem !important;
          }
          span {
            display: block;
            text-align: left;
            &.primary {
              color: #000 !important;
            }
            &.success {
              color: #000 !important;
            }
            &.danger {
              color: #000 !important;
            }
            small {
              font-size: 1.6rem;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .gasComponents {
    /deep/.fgGas {
      
      margin: 0;
      margin-top: -8rem;
    }
  }
}
@media only screen and (max-width: 768px) {
  .gasComponents {
    /deep/.fgGas {
      margin: 0;
      margin-top: -8rem;
      .card {
        .card-header {
          display: block;
          height: auto;
          padding: 0 1.5rem;
          h4 {
            padding-top: 1.5rem;
          }
          .fgGasNav {
            margin-bottom: -1px;
          }
        }
        .card-body {
          .col-lg-4 {
            margin-bottom: 1rem;
          }
        }
      }
    }
  }
}
</style>
<style scoped>
/* 可以设置不同的进入和离开动画 */
/* 设置持续时间和动画函数 */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active, 2.1.8 版本以下 */ {
  opacity: 0;
}
</style>
