<template>
  <div class="chart-m-l-c" :class="{ hideColor: ifShotting }">
    <span>{{ $t("m.blockHeight") }}</span>
    <div class="count">
      <b v-for="(item, index) in prosecutorArr" :key="index">
        <div :style="{ top: -item * 20 + 'px' }" v-show="show">
          <i v-for="(ic, indexc) in 10" :key="indexc">{{ ic - 1 }}</i>
        </div>
      </b>
    </div>
  </div>
</template>
<script>
export default {
  props: ["heightNumber", "ifShotting"],
  data() {
    return {
      show: false,
      prosecutorArr: ["0", "0", "0", "0", "0", "0"],
      //这里的数字数组是个6位数，只是占位，不能直接使用，看需求有可能是10位或11、12、20等等
      numbers: 410000,
    };
  },
  watch: {
    heightNumber: {
      handler(newVal, oldVal) {
        this.numbers = newVal;
        this.plusNPrAll();
      },
      deep: true,
    },
  },
  methods: {
    PrefixInteger(num, length) {
      //给数字前面补零 比如‘59832’补成8位即位‘00059832’
      return (Array(length).join("0") + num).slice(-length);
    },
    plusNPrAll() {
      const res = this.PrefixInteger(this.numbers, this.numbers.length);
      this.prosecutorArr = res.toString().split("");
    },
  },
  mounted() {
    this.numbers = this.heightNumber;
    if (this.numbers) this.show = true;
    this.plusNPrAll();
  },
};
</script>
<style lang="less" scoped>
.chart-m-l-c {
  display: inline-flex;
  width: auto;
  margin: 0;
  align-items: center;
  text-align: center;
  span {
    display: inline-block;
    height: 20px;
    line-height: 20px;
    font-size: 1rem;
    // font-weight: 600;
    z-index: 10;
    text-align: center;
    // background-image: linear-gradient(
    //   0deg,
    //   rgb(220 54 200) 39%,
    //   #2989d8 100%,
    //   #2cd4d9 0
    // );
    -webkit-background-clip: text;
    color: #bbb;
  }
  .count {
    display: inline-block;
    width: auto;
    min-width: 65px;
    height: 20px;
    vertical-align: middle;
    box-sizing: content-box;
    b {
      display: inline-block;
      width: 10px;
      height: 20px;
      // border: 1px solid #ddd;
      color: #bbb;
      text-align: center;
      line-height: 20px;
      position: relative;
      z-index: 3;
      overflow: hidden;
      div {
        width: 100%;
        height: 400px;
        position: absolute;
        left: 0;
        top: 0;
        transition: all 0.8s ease-in-out;
        i {
          width: 100%;
          height: 20px;
          float: left;
          font-style: normal;
          font-size: 1rem;
          color: #bbb;
          // background-image: linear-gradient(
          //   0deg,
          //   rgb(220 54 200) 39%,
          //   #2989d8 100%,
          //   #2cd4d9 0
          // );
          -webkit-background-clip: text;
          // color: transparent;
        }
      }
    }
  }
  span {
    line-height: 20px;
    float: left;
  }
  span.iconfont {
    width: 40px;
    height: 40px;
    line-height: 40px;
    float: right;
    text-align: center;
    cursor: pointer;
    color: #0071ff;
  }
  span.iconfont:hover {
    color: #5ec2a6;
  }
}
.hideColor {
  strong {
    background-image: none;
    -webkit-background-clip: unset;
    color: #999;
  }
  .count {
    b {
      div {
        i {
          background-image: none;
          -webkit-background-clip: unset;
          color: #999;
        }
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .chart-m-l-c {
    display: none;
  }
}
</style>
