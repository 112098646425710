<template>
  <div class="bannerAd">
    <a href="https://www.wenjuan.com/s/UZBZJvrxDc/" target="_blank"
      ><div class="bannerAdBox"></div
    ></a>
  </div>
</template>

<script>
export default {
  name: "shareImg",

  data() {
    return {};
  },
  props: ["src", "popShow"],
  created() {},
  methods: {
    closeMe() {
      this.$emit("update:popShow", "");
    },
  },
};
</script>

<style lang="less" scoped>
.bannerAd {
  width: 100%;
  .bannerAdBox {
    width: 100%;
    padding-top: 5.3%;
    background: url("/img/ad.png") no-repeat center center / auto
      100%;
  }
}
@media only screen and (max-width: 1200px) {
  .bannerAd {
    width: 100%;
    .bannerAdBox {
      width: 100%;
      padding-top: 10%;
      background: url("/img/ad.png") no-repeat top center / 100%
        auto;
    }
  }
}
@media only screen and (max-width: 720px) {
  .bannerAd {
    width: 100%;
    .bannerAdBox {
      width: 100%;
      padding-top: 15%;
      background: url("/img/ad.png") no-repeat top center / 100%
        auto;
    }
  }
}
</style>