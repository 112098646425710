<template>
  <div id="home" class="home" ref="imageToFile">
    <div id="shotScreen">
      <div class="titles">
        <h3>Gas实时查</h3>
        <h1>水滴实验室出品 V.1.0</h1>
      </div>
      <Gas :time="totalSteps" :countdown.sync="countDown" />
    </div>
    <!-- <Sectors :time="totalSteps" :countdown.sync="countDown" /> -->

    <div class="bannerAd">
      <a href="https://m.ssdminer.cn/" target="_blank"
        ><div class="bannerAdBox">
          <img src="img/ad.png" alt="" /></div
      ></a>
    </div>
    <Coin :time="totalSteps" :countdown.sync="countDown" />
    <div class="content">
      <div class="filecoinPirceBox">
        <!-- <div class="voteBox" v-show="showVoteBox">
          <h3>{{ $t("m.voteTitle") }}</h3>
          <transition name="fade">
            <div class="vote" v-show="showVote">
              <span @click="vote(1)"><img :src="imgList.zan" alt=""/></span>
              <b>/</b>
              <span @click="vote(2)"><img :src="imgList.cai" alt=""/></span>
            </div>
          </transition>
          <transition name="fade">
            <div class="rate" v-show="!showVote">
              <i class="iconhuo"><img :src="imgList.zan" alt=""/></i>
              <span
                class="progressBar goodBar"
                :style="'width:' + goodPercent + '%'"
                >{{ goodPercent }}%</span
              >
              <span
                class="progressBar badBar"
                :style="'width:' + badPercent + '%'"
                >{{ badPercent }}%</span
              >
              <i class="iconshui"><img :src="imgList.cai" alt=""/></i>
            </div>
          </transition>
        </div> -->
      </div>

      <div class="interactiveBox">
        <div class="clientOnly">
          <div class="codeBox">
            <img src="img/code.png" alt="" />
            <div class="fontBox">
              <h3>扫码关注水滴云</h3>
              <p>{{ $t("m.refreshTime") }}<br />{{ flashTime }}</p>
            </div>
          </div>
        </div>
        <div class="shareBtn socialBtns">
          <share :config="config"></share>
        </div>
        <div class="shareBtn shotScreen" @click="shareImg()">
          <i class="iconfont iconfenxiang"></i>
          {{ $t("m.share") }}
        </div>
      </div>
      <share-img :popShow.sync="shotImg" :src.sync="shotImg"></share-img>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
// import html2canvas from "html2canvas";
import domtoimage from "dom-to-image";

import Gas from "./components/Gas";
import Coin from "./components/Coin";
import Sectors from "./components/Sectors";
import RadialProgressBar from "../../components/RadialProgressBar";
import shareImg from "../../components/popShare";
import computeNumber from "../../components/scrollNumber";
import Clipboard from "clipboard";
import BannerAd from "../../components/bannerAd.vue";

export default {
  // asyncData: async ({ store }) => {
  //   let [request1Data, request2Data, request3Data] = [
  //     await store.dispatch("GET_ARTICLE", { type: "32G" }),
  //     await store.dispatch("GET_COIN"),
  //     await store.dispatch("GET_MINERS"),
  //   ];
  //   return {
  //     data1: request1Data,
  //     data2: request2Data,
  //     data3: request3Data,
  //   };
  // },
  components: {
    Gas,
    Coin,
    Sectors,
    BannerAd,
    RadialProgressBar,
    shareImg,
    domtoimage,
    computeNumber,
  },
  data() {
    return {
      config: {
        url: "https://fgas.io",
        title: "Fgas.io - Filecoin数据站 实时手续费查询",
        image: "../img/logo.png",
        description:
          "Fgas - 专业的Filecoin数据展示网站，查询实时币价、Gas费用、扇区封装费、集群收益、回本周期等各类数据。",
        sites: ["wechat", "facebook", "qq", "twitter"], // 启用的站点
      },
      imgList: {
        zan: "../img/zan.png",
        cai: "../img/cai.png",
      },
      copyTest: "",
      zhLang: true,
      flashTime: new Date().toLocaleString(),
      completedSteps: 0,
      totalSteps: 30000,
      countDownTimer: "",
      countDown: 0,
      nowTime: new Date(),
      // article: [],
      coin: [],
      timer: "",
      lang: "",
      shotImg: "",
      testData: [
        {
          liquidity: 377,
          name: "Huobi Global",
          price: "21.57",
          sourceImg: "../img/huobi.png",
        },
        {
          liquidity: 377,
          name: "Huobi Global",
          price: "21.57",
          sourceImg: "../img/huobi.png",
        },
        {
          liquidity: 377,
          name: "Huobi Global",
          price: "21.57",
          sourceImg: "../img/huobi.png",
        },
      ],
      serverTimeMark: "",
      localTimeMark: "",
      goodRate: "",
      badRate: "",
      goodPercent: 50,
      badPercent: 50,
      showVote: true,
      showVoteBox: false,
      yourVote: "",
      shotting: false,
    };
  },
  created() {
    this.$store.dispatch("GET_ARTICLE", { type: "32G" });
    this.$store.dispatch("GET_COIN");
  },
  mounted() {
    if (this.$cookies.get("localip") === null) {
      setTimeout(() => {
        this.showVoteBox = true;
        let cip = "local";
        if (returnCitySN["cip"]) {
          cip = returnCitySN["cip"] + returnCitySN["cname"];
          this.$cookies.set("localip", cip, "20h");
        }
        axios
          .get(`${this.$originHost}/api/v1/vote_rate?local=${cip}`)
          .then((res) => {
            this.serverTimeMark = res.data.markTime.replace(/\s+/g, "");
            this.goodRate = res.data.goodRate;
            this.badRate = res.data.badRate;
            this.goodPercent = (this.goodRate * 100).toFixed(2);
            this.badPercent = (this.badRate * 100).toFixed(2);
            // console.log(this.serverTimeMark, this.goodRate, this.badRate);
            if (this.$cookies.isKey("localTimeMark"))
              this.localTimeMark = this.$cookies.get("localTimeMark");
            if (
              this.localTimeMark.length &&
              this.localTimeMark === this.serverTimeMark
            ) {
              this.showVote = false;
            } else {
              // console.log(this.localTimeMark, this.serverTimeMark);
            }
          })
          .catch((err) => {
            console.log("voteRate catch", err.response);
          });
      }, 1000);
    }

    this.countDownTimer = setInterval(() => {
      if (this.countDown === 30000) {
        this.countDown = 0;
        this.flashTime = new Date().toLocaleString();
      } else {
        this.countDown = this.countDown + 1000;
      }
      this.completedSteps = this.countDown;
    }, 1000);
  },
  methods: {
    vote(num) {
      this.showVote = false;
      axios
        .post(`${this.$originHost}/api/v1/vote`, { vote: num })
        .then((res) => {
          // console.log(res.data);
          this.serverTimeMark = res.data.markTime.replace(/\s+/g, "");
          this.goodRate = res.data.goodRate;
          this.badRate = res.data.badRate;
          this.goodPercent = (this.goodRate * 100).toFixed(2);
          this.badPercent = (this.badRate * 100).toFixed(2);
          this.$cookies.set("localTimeMark", this.serverTimeMark, "20h"); // 20H后过期
        });
    },
    // shareImg() { //html2canvas截图
    //   window.pageYOffset = 0;
    //   document.documentElement.scrollTop = 0;
    //   document.body.scrollTop = 0;
    //   html2canvas(this.$refs.imageToFile, {
    //     // width:
    //     //   window.innerWidth ||
    //     //   document.documentElement.clientWidth ||
    //     //   document.body.clientWidth,
    //     // height:
    //     //   window.innerHeight ||
    //     //   document.documentElement.clientHeight ||
    //     //   document.body.clientHeight,
    //     height: document.getElementById("home").scrollHeight,
    //     windowHeight: document.getElementById("home").scrollHeight,
    //   }).then((canvas) => {
    //     // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
    //     this.shotImg = canvas.toDataURL("image/png");
    //   });
    // },
    shareImg() {
      let _this = this;
      this.shotting = true;
      let node = document.getElementById("home");
      domtoimage
        .toPng(node, { bgcolor: "#fff" })
        .then((dataUrl) => {
          this.shotImg = dataUrl;
          this.shotting = false;
        })
        .catch(function (error) {
          console.error("oops, something went wrong!", error);
          _this.shotting = false;
        });
    },
    reload() {
      clearInterval(this.countDownTimer);
      this.countDown = 0;
      this.flashTime = new Date().toLocaleString();
      this.completedSteps = this.countDown;
      this.$message.loading("Loading...", 1).then(() => {
        this.countDownTimer = setInterval(() => {
          if (this.countDown === 30000) {
            this.countDown = 0;
            this.flashTime = new Date().toLocaleString();
          } else {
            this.countDown = this.countDown + 1000;
          }
          this.completedSteps = this.countDown;
        }, 1000);
        this.$message.success("Loading finished", 1);
      });
    },
    copyUrl() {
      this.copyTest = window.location.href;
      let clipboard = new Clipboard(".copy"); // 这里括号里填写上面点击事件绑定的class名
      // console.log(clipboard);
      let msg = "";
      if (this.lang === "zh-CN") {
        msg = "链接复制成功，快去分享吧！";
      } else {
        msg = "Successfully Copy The Link Into Clipboard!";
      }
      clipboard.on("success", (e) => {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: msg,
        });
        // console.info("Action:", e.action);
        // console.info("Text:", e.text);
        // console.info("Trigger:", e.trigger);

        e.clearSelection();
      });

      clipboard.on("error", function (e) {
        console.error("Action:", e.action);
        console.error("Trigger:", e.trigger);
      });
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
    clearInterval(this.countDownTimer);
  },
};
</script>
<style lang="less" scoped>
#home {
  text-align: center;
  .bannerAd {
    padding: 0 15px;
    margin-bottom: 15px;
    img {
      width: 100%;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    }
  }
  .titles {
    padding: 2.5rem 0 10.5rem;
    margin: 0 0 0 0;
    background: -moz-linear-gradient(left, #01d3bd 0%, #0479e2 100%);
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0%, #01d3bd),
      color-stop(100%, #0479e2)
    );
    background: -webkit-linear-gradient(left, #01d3bd 0%, #0479e2 100%);
    background: -o-linear-gradient(left, #01d3bd 0%, #0479e2 100%);
    background: -ms-linear-gradient(left, #01d3bd 0%, #0479e2 100%);
    background: linear-gradient(to right, #01d3bd 0%, #0479e2 100%);
    h1 {
      font-size: 1.2rem;
      color: #000;
    }
    h3 {
      font-size: 2.4rem;
      color: #000;
    }
  }
  .content {
    position: relative;
    display: inline-block;
    width: 1200px;
    margin: 0 auto;
    .filecoinPirceBox {
      // padding: 0 20px;
      // margin: 20px 0;
      overflow: hidden;
      table {
        float: left;
        margin: 0 20px;
      }
    }
    table {
      width: 50%;
      height: 140px;
      margin: 20px auto;
      border-collapse: collapse; /*边框合并*/
      tr.odd {
        /*表格的奇数列的样式*/
        background-color: #f8f8f8;
      }
      tr.head {
        /*表头的下边框*/
        border-bottom-width: 2px;
        .headTd {
          text-align: left;
          width: auto;
        }
      }
      tr {
        border-bottom-style: solid; /*底部为实线*/
        border-bottom-width: 1px; /*底部宽度为1px*/
        border-bottom-color: #eee; /*底部颜色*/
        height: 35px; /*高度为35px*/
        .sourceBox {
          text-align: left;
          width: auto;
          img {
            float: left;
            width: 20px;
            height: 20px;
            margin-right: 5px;
          }
        }
      }
      .tableLoading {
        height: 105px;
        td {
          width: 100%;
          height: 105px;
          text-align: center;
          vertical-align: middle;
        }
      }
      .head {
        background: rgba(26, 79, 201, 0.8);
        color: #fff;
      }

      td {
        width: 25%;
        padding: 0 10px;
        line-height: 20px;
        text-align: center; /*文字居中*/
      }
    }

    .voteBox {
      position: relative;
      height: 80px;
      width: 50%;
      margin-top: 1.5rem;
      margin: auto;
      overflow: hidden;
      h3 {
        margin-bottom: 20px;
        font-size: 1.6rem;
      }
      .vote {
        position: absolute;
        top: 45px;
        left: 0;
        width: 100%;
        height: 35px;
        line-height: 35px;
        overflow: hidden;
        b {
          display: inline-block;
          height: 35px;
          vertical-align: top;
          font-weight: 400;
          font-size: 1.4rem;
        }
        span {
          display: inline-block;
          width: 35px;
          height: 35px;
          margin: 0 2%;
          overflow: hidden;
          box-sizing: content-box;
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .rate {
        position: absolute;
        top: 55px;
        left: 50%;
        width: 90%;
        padding: 0 20px;
        margin-left: -45%;
        i {
          position: absolute;
          top: -3px;
          width: 25px;
          height: 25px;
          &:before {
            display: none;
            font-size: 1.8rem;
          }
          img {
            width: 100%;
          }
        }
        .iconhuo {
          left: -8px;
        }
        .iconshui {
          right: -8px;
        }
        span {
          float: left;
          display: inline-block;
          width: 50%;
          height: 20px;
          line-height: 20px;
          color: #fff;
          transition: all 0.1s ease-in-out;
          &:hover {
            transform: scale(1.05);
          }
        }
        .goodBar {
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
          background-color: #3de248;
        }
        .badBar {
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          background-color: #f05541;
        }
      }
    }
    .interactiveBox {
      margin: 20px 0;
      /deep/.clientOnly {
        position: relative;
        display: inline-block;
        text-align: center;
        .codeBox {
          img {
            float: left;
            width: 60px;
            height: 60px;
            margin-right: 15px;
          }
          .fontBox {
            text-align: left;
            overflow: hidden;
            h3 {
              font-size: 1.2rem;
              margin-bottom: 1rem;
            }
            p {
              font-size: 1rem;
            }
          }
        }
        .radial-progress-container {
          margin: 0 auto;
        }
        .reloadBtn {
          width: 100%;
          height: 100%;
          border: 0;
          background-color: transparent;
        }
        // &:before {
        //   content: "";
        //   position: absolute;
        //   right: -10px;
        //   top: 50%;
        //   display: block;
        //   width: 1px;
        //   height: 30px;
        //   margin-top: -15px;
        //   background-color: #1a4fc9;
        // }
      }
      .shareBtn {
        display: inline-flex;
        height: 30px;
        margin: 30px 0 0 30px;
        padding: 0 10px;
        border-radius: 5px;
        background-color: #0cc2d3;
        font-weight: 400;
        font-size: 1.2rem;
        color: #fff;
        cursor: pointer;
        vertical-align: top;
        align-items: center;
        i {
          &:before {
            font-size: 1.6rem;
          }
        }
      }
      .shotScreen {
        display: none;
      }
      .socialBtns {
        background-color: transparent;
      }
    }
    .homePageTitle {
      padding: 0 20px;
      font-size: 2rem;
      text-align: center;
      color: #1a4fc9;
      opacity: 0.8;
    }
  }
}
@media only screen and (max-width: 1200px) {
  #home {
    .content {
      width: 100%;
      .homePageTitle {
        font-size: 2rem;
      }
      .interactiveBox {
        .shotScreen {
          display: inline-flex;
        }
        .copy,
        .socialBtns {
          display: none;
        }
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  #home {
    .content {
      .filecoinPirceBox {
        table {
          float: unset;
          width: 90%;
          margin: 0 auto;
        }
        .voteBox {
          width: 100%;
          margin: 20px 0;
        }
      }
      .homePageTitle {
        font-size: 1.6rem;
      }
    }
  }
}
</style>
<style scoped>
/* 可以设置不同的进入和离开动画 */
/* 设置持续时间和动画函数 */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active, 2.1.8 版本以下 */ {
  opacity: 0;
}
</style>
